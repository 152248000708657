import React from "react";
import styled from "@emotion/styled";

import "../css/fonts.css";
import "./layout.css";

const Layout = styled("div")`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

export default Layout;
